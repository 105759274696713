.glowLight {
  text-shadow: 0 0 8px rgba(138, 0, 252, 0.45), 2px 2px 2px rgba(138, 0, 252, 0);
}

.glowDark {
  text-shadow: 0 0 8px rgba(235, 235, 240, 0.45),
    2px 2px 2px rgba(138, 0, 252, 0);
}

.glowLightBox {
  box-shadow: 0 0 8px rgba(138, 0, 252, 0.25), 2px 2px 2px rgba(138, 0, 252, 0);
}

.glowDarkBox {
  box-shadow: 0 0 8px rgba(235, 235, 240, 0.25),
    2px 2px 2px rgba(138, 0, 252, 0);
}
