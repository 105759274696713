.btnoffer {
  border-radius: 5px;
  border: 1px solid #b3b3bc;
  padding: 15px;
}

.btnoffer + .btnoffer {
  margin-left: 1rem;
}

.active {
  border: 2px solid #7416e6;
  border-radius: 5px;
  background-color: #f0dfff;
}
