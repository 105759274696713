.list * {
  @apply text-base leading-6;
}

.list > ul {
  @apply space-y-1 m-0;
}

.list p {
  @apply mb-2;
}

.list > ul > li::before {
  @apply font-bold inline-block w-4;

  content: '\2022';
}

.list > ol {
  @apply list-decimal space-y-1 m-0 pl-8;
}
