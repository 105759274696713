.list > ul {
  list-style: none;
  padding-left: 1.5rem;
  font-size: 14px;
}

.list > ul > li::before {
  content: '\2022';
  color: #8a00fc;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
