.mainSpacing {
  padding-top: var(--navSpaceSm);
}

@screen md {
  .mainSpacing {
    padding-top: var(--navSpaceMd);
  }
}

@screen lg {
  .mainSpacing {
    padding-top: var(--navSpaceLg);
  }
}

.progressError {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: #ffefed;
}

.progressError > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: #ffefed;
}

.progressError > div > div > p,
.progressDefault > div > div > p,
.progressSuccess > div > div > p,
.progressInfo > div > div > p,
.progressWarning > div > div > p {
  color: #313234;
}

.progressError > button,
.progressDefault > button,
.progressSuccess > button,
.progressInfo > button,
.progressWarning > button {
  color: #91919b;
  opacity: 1 !important;
}

.progressError > div:last-child {
  background-color: #d11f07;
  height: 4px !important;
}

.progressSuccess {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: #e7fbe9;
}

.progressSuccess > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: #e7fbe9;
}

.progressSuccess > div:last-child {
  background-color: #15880b;
  height: 4px !important;
}

/* Toastify__progress-bar Toastify__progress-bar--animated Toastify__progress-bar--info */

.progressWarning {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: #fff3d0;
}

.progressWarning > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: #fff3d0;
}

.progressWarning > div:last-child {
  background-color: #d36912;
  height: 4px !important;
}

.progressDefault,
.progressInfo {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: rgba(238, 242, 255, 1);
}

.progressDefault > div:first-child,
.progressInfo > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: rgba(238, 242, 255, 1);
}

.progressDefault > div:last-child,
.progressInfo > div:last-child {
  background-color: rgba(67, 56, 202, 1);
  height: 4px !important;
}

.progressDark {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  min-height: 64px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  background-color: #313234;
}

.progressDark > div:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px;
  background-color: #313234;
}

.progressDark > div > div > p {
  color: #f5f5f8;
}

.progressDark > button {
  color: #f5f5f8;
}

.progressDark > div:last-child {
  background-color: #dbdbe2;
}
