.cardContainer {
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  scroll-padding: 1rem;
  grid-gap: 10px;
  grid-template-columns: 10px;
  grid-auto-flow: column;
  padding-bottom: calc(0.75 * 20px);
  margin-bottom: calc(-0.25 * 20px);
  grid-template-rows: minmax(150px, 1fr);
}

.cardContainerFewSwim {
  justify-content: center;
}

.cardContainer::before,
.cardContainer::after {
  content: '';
  width: 10px;
}

.containerItem {
  scroll-snap-align: center;
  display: inline-block;
  padding: calc(20px / 2 * 1.5);
}

.wrapperSelect {
  display: grid;
  justify-content: center;
}

.wrapperProduct {
  display: grid;
  justify-content: inherit;
}

.wrapper > * {
  grid-column: 2 / -2;
}

.wrapper > .containerItem {
  grid-column: 1 / -1;
}

.grabbyHand {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbyHand:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
