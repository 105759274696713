.inverted {
  background: #1a1c1e;
}

.base {
  background: #f5f5f8;
}

.dataOverlayScroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Default Modals */
.dataOverlay {
  width: 100vw;
  margin: 0;
  height: 100vh;
  height: 100dvh;
  overflow-y: auto;
  padding: 1.25rem;
  align-self: center;
}

@media only screen and (min-width: 768px) {
  .dataOverlay {
    height: auto;
    width: auto;
    width: 75vw;
    margin: 55px auto 0;
    max-width: 670px;
    max-height: 85vh;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 1281px) {
  .dataOverlay {
    height: auto;
    width: 50vw;
    max-width: 670px;
    margin: 55px auto 0;
    max-height: 85vh;
    border-radius: 8px;
  }
}

/* Wide Modals */
.dataOverlayWide {
  width: 100vw;
  margin: 0;
  height: 100vh;
  padding: 1.25rem;
  overflow-y: hidden;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .dataOverlayWide {
    margin: 55px auto 0;
    width: 75vw;
    max-width: 850px;
    max-height: 800px;
    height: 85vh;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 1023px) {
  .dataOverlayWide {
    width: 85vw;
    max-width: 900px;
    margin: 55px auto 0;
    height: 80vh;
    max-height: 700px;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 2000px) {
  .dataOverlayWide {
    width: 85vw;
    max-width: 1000px;
    margin: 55px auto 0;
    height: 80vh;
    max-height: 850px;
    border-radius: 8px;
  }
}
