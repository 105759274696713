.list * {
  @apply text-base leading-6;
}

.list > ul {
  @apply space-y-1 m-0;
}

.list p {
  @apply mb-4;
}

.list > ul > li::before {
  @apply font-bold inline-block text-nzxt-volt-400 w-4;

  content: '\2022';
}
