.quantity {
  appearance: textfield;
  @apply w-12 text-nzxt-light-grey-500 border-none mx-0 p-0 rounded text-center text-base text-black relative h-full max-w-full;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  @apply appearance-none m-0;
}

.counterInput {
  will-change: transform;
}

.counter {
  display: inline-grid;
  height: 100%;
  grid-template-columns: auto auto auto;
}
