.textOverlay::before {
  content: '';
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  height: 50%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.5rem;
  transition: all 1s;
}

.textOverlayMain::before {
  content: '';
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  height: 50%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 1s;
}

.noTextOverlay::before {
  content: '';
  opacity: 0;
  transition: all 1s;
}

.YesTextOverlay::before {
  content: '';
  opacity: 1;
  transition: all 1s;
}

.thumbnailSection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  height: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

.thumbnailSection::-webkit-scrollbar {
  height: 0;
  width: 0;
  background-color: rgba(0, 0, 0, 0);
}

.thumbnailSection::-webkit-scrollbar-thumb {
  border-radius: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0);
}

.verticalThumbnails::-webkit-scrollbar {
  width: 8px;
}

.verticalThumbnails::-webkit-scrollbar-thumb {
  border-radius: 39px;
  background-color: #686775;
}

.ytlite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  @apply w-full;
}

.ytlite::after {
  content: '';
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}

.ytlite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ytlite > .ltyPlaybtn {
  width: 70px;
  height: 46px;
  background-color: #f00;
  z-index: 1;
  opacity: 1;
  border-radius: 14%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.ytlite:hover > .ltyPlaybtn {
  background-color: #f00;
  opacity: 1;
}

/* play button triangle */
.ytlite > .ltyPlaybtn::before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.ytlite > .ltyPlaybtn,
.ytlite > .ltyPlaybtn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.ytlite.lytActivated {
  cursor: unset;
}

.ytlite.lytActivated::before,
.ytlite.lytActivated > .ltyPlaybtn {
  opacity: 0;
  pointer-events: none;
}
