.affirm > span {
  @apply text-nzxt-light-grey-800;
}

:global(.dark) .affirm,
:global(.dark) .affirm > span,
:global(.dark) .affirm > span::after {
  @apply text-nzxt-dark-grey-200 !important;
}

.affirm > a:hover {
  text-decoration: underline;
}

.affirm > a {
  @apply text-nzxt-blue-400;
}

:global(.dark) .affirm > a {
  @apply text-nzxt-blue-300;
}

:global(.bld-summary) .affirm {
  @apply text-left;
  @apply md:text-right;
  @apply block;
  @apply text-xs;
}

:global(.bld-summary) .affirm > a {
  @apply block;
}

.lightAffirm > a:hover {
  text-decoration: underline;
}

.lightAffirm > a {
  color: #fff;
}
