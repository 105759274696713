.testimonialFade {
  position: relative;
}

.testimonialFade::before,
.testimonialFade::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  height: 100%;
  width: 2rem;
  pointer-events: none;
  z-index: 1;
}

.testimonialFade::before {
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  left: 0;
}

.testimonialFade::after {
  background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
  right: 0;
}
