.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 45;
  height: 100vh;
  width: 100vw;
  background-color: rgba(47, 46, 52, 0.5);
}

@supports (
  (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))
) {
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 45;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
  }
}
