.hoverItem {
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.hoverWrapper:hover > .hoverItem {
  opacity: 0.85;
}

.hoverWrapper:hover > a > div:nth-child(2) {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.hoverWrapper > a > div:nth-child(2) {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.hoverItem:focus-visible {
  opacity: 1;
  transition: all 0.15s ease-in-out;
}

.hoverItem:active {
  opacity: 0;
}
