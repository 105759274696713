.clipText {
  font-size: 2rem;
}

@screen lg {
  .clipText {
    font-size: 4rem;
  }
}

.clipTextTwo {
  --text-size: 5rem;
  @apply bg-bottom;

  font-size: var(--text-size);
  margin-bottom: calc(-1 * (calc(var(--text-size) / 2) * 0.29));
}

@screen md {
  .clipTextTwo {
    --text-size: 8rem;
  }
}

@screen lg {
  .clipTextTwo {
    --text-size: 10rem;
  }
}

@screen xl {
  .clipTextTwo {
    --text-size: 12rem;
  }
}

@screen 2xl {
  .clipTextTwo {
    --text-size: 15rem;
  }
}

.gridNegativeSpace {
  --image-height: 300px;
  --image-offset: 0.75;

  margin-top: calc(-1 * (var(--image-height) * var(--image-offset)));
}

@screen md {
  .gridNegativeSpace {
    --image-height: 382px;
  }
}

@screen lg {
  .gridNegativeSpace {
    --image-height: 369px;
  }
}

@screen xl {
  .gridNegativeSpace {
    --image-height: 473px;
  }
}

@screen 2xl {
  .gridNegativeSpace {
    --image-height: 577px;
  }
}

.bgCon {
  @apply bg-bottom relative z-20 bg-cover;

  overflow: hidden;
  background-repeat: no-repeat;
  padding-bottom: 17rem;
}

@screen md {
  .bgCon {
    @apply rounded-md;

    padding-bottom: 21rem;
  }
}

@screen lg {
  .bgCon {
    padding-bottom: 20rem;
  }
}

@screen xl {
  .bgCon {
    padding-bottom: 26rem;
  }
}

@screen 2xl {
  .bgCon {
    height: auto;
    min-height: auto;
    padding-bottom: 31.25rem;
  }
}
