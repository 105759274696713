.swatchDefault,
.swatchSmall {
  position: relative;
}

.swatchDefault::before {
  content: '';
  position: absolute;
  box-shadow: 0 0 0 3px hsl(0deg 0% 100% / 0%), 0 0 0 4px hsl(0deg 0% 84% / 0%),
    inset 0 1px 6px 2px rgb(0 0 0 / 20%);
  height: 26px;
  width: 26px;
  left: -13px;
  top: -13px;
  right: 0;
  border-radius: 50%;
  z-index: 1;
}

.swatchSmall::before {
  content: '';
  position: absolute;
  box-shadow: 0 0 0 3px hsl(0deg 0% 100% / 0%), 0 0 0 4px hsl(0deg 0% 84% / 0%),
    inset 0 1px 6px 2px rgb(0 0 0 / 20%);
  height: 22px;
  width: 22px;
  left: -11px;
  top: -11px;
  right: 0;
  border-radius: 50%;
  z-index: 1;
}
